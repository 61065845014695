import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../../layout"
import SiteHead from "../../head"
import { guideContainer, guidePaddingBottom } from "../../utils/styles"
import Stars from "../../components/blog/stars"
import HeroContainer from "../../components/containers/hero"
import Title from "../../components/hero/title"
import { rhythm } from "../../utils/typography"
import BlogPostPreviewItem from "../../components/blog/blog-post-preview-item"

const styles = theme => ({
  container: {
    overflowX: `hidden`,
    position: `relative`,
    zIndex: 2,
  },
  authorWrapper: {
    display: `flex`,
    textAlign: `center`,
    flexDirection: `column`,
    alignItems: `center`,
    span: {
      marginBottom: theme.space[4],
    },
    marginBottom: theme.space[12],
  },
  bioStyles: {
    lineHeight: theme.lineHeights.dense,
    maxWidth: rhythm(20),
    marginBottom: theme.space[4],
  },
})

export function Head(props) {
  const user = props.data.wpUser

  return (
    <SiteHead
      {...props}
      title={`${user.name} - Contributor`}
      description={`All posts by ${user.name} on the Gatsby blog.`}
    />
  )
}

export default function ContributorsAuthorPage({
  location,
  data: { wpUser: user },
}) {
  return (
    <Layout pathname={location.pathname}>
      <div
        css={theme => ({
          ...styles(theme).container,
          ...guidePaddingBottom(theme),
          position: `relative`,
        })}
      >
        <Stars />
        <HeroContainer>
          <div css={theme => guideContainer(theme)}>
            <section css={theme => styles(theme).authorWrapper}>
              {user.authorInfo?.avatar?.gatsbyImage && (
                <GatsbyImage
                  image={user.authorInfo.avatar.gatsbyImage}
                  alt={user.name}
                  layout="fixed"
                  css={theme => ({
                    borderRadius: theme.radii[5],
                    objectFit: "cover",
                  })}
                />
              )}
              <Title
                css={theme => ({
                  maxWidth: rhythm(16),
                  [theme.mediaQueries.phablet]: {
                    maxWidth: rhythm(20),
                  },
                  [theme.mediaQueries.tablet]: {
                    fontSize: theme.fontSizes[7],
                  },
                  [theme.mediaQueries.hd]: {
                    fontSize: theme.fontSizes[8],
                  },
                })}
              >
                {user.name}
              </Title>
              {user.authorInfo?.bio && (
                <p css={theme => styles(theme).bioStyles}>
                  {user.authorInfo.bio}
                </p>
              )}
              {user.authorInfo?.twitter && (
                <p css={theme => styles(theme).bioStyles}>
                  <a
                    href={`https://www.twitter.com/${user.authorInfo.twitter}`}
                  >
                    {user.authorInfo.twitter}
                  </a>
                </p>
              )}
            </section>
            {user.posts?.nodes?.map(post => (
              <BlogPostPreviewItem post={post} key={post.slug} Heading="h2" />
            ))}
          </div>
        </HeroContainer>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ContributorsAuthorPage($id: String!) {
    wpUser(id: { eq: $id }) {
      name
      slug
      authorInfo {
        twitter
        bio
        avatar {
          gatsbyImage(layout: FIXED, width: 64, height: 64)
        }
      }
      posts {
        nodes {
          ...BlogPostPreviewInformation
        }
      }
    }
  }
`
